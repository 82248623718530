
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
	computed: {
		logo(){
      console.log('window.location.hostname: ', window.location.hostname);
      if(window.location.hostname === 'partners.go2app.ai') {
        return '/media/logos/g2a-logo-2.svg';
      } else if (window.location.hostname === 'partners.zilmtech.app') {
        return '/media/logos/zilmtech-logo-w.png';
      } else {
        return '/media/logos/Z2A_Digital_Logotyp_Alt_RGB_Achromatic_Black.png';
      }
		}
	},
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }


      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          Swal.fire({
            text: "You have successfully logged in!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          }).then(function () {
            // Go to page after successfully login
            router.push({ name: "dashboard" });
          });
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;

    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
